import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    rwcuti: {
      id: '',
      jenis_cuti: {
          id: '',
          nama: ''
      },
      sk: {
          pejabat: '',
          nomor: '',
          tanggal: ''
      },
      periode: {
          awal: '',
          akhir: ''
      },
      alasan: '',
      alamat: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
  }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwcuti = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})