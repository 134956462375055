import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    
    rwcuti: {
      id: '',
      jenis_cuti: {
          id: '',
          nama: ''
      },
      sk: {
          pejabat: '',
          nomor: '',
          tanggal: ''
      },
      periode: {
          awal: '',
          akhir: ''
      },
      alasan: '',
      alamat: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
  }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwcuti = value
    },
  }
})