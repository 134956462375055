<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Edit Riwayat Cuti
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Jenis Cuti</span>
            </v-col>

            <v-col cols="12" md="10">
              <v-select
                v-model="eItem.jenis_cuti.id"
                class="mt-2"
                :items="refjencut"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Alasan</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-textarea
                v-model="eItem.alasan"
                class="mt-2"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Surat Keputusan (SK)</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="eItem.sk.pejabat"
                class="mt-2"
                hint="Pejabat"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="eItem.sk.nomor"
                class="mt-2"
                hint="Nomor"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="picksk"
                v-model="picksk"
                :close-on-content-click="false"
                :return-value.sync="eItem.sk.tanggal"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eItem.sk.tanggal"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eItem.sk.tanggal" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="picksk = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.picksk.save(eItem.sk.tanggal)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Periode Cuti</span>
            </v-col>
            <v-col cols="12" md="5">
              <v-menu
                ref="pickawal"
                v-model="pickawal"
                :close-on-content-click="false"
                :return-value.sync="eItem.periode.awal"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eItem.periode.awal"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal Awal"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eItem.periode.awal" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pickawal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pickawal.save(eItem.periode.awal)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5">
              <v-menu
                ref="pickakhir"
                v-model="pickakhir"
                :close-on-content-click="false"
                :return-value.sync="eItem.periode.akhir"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eItem.periode.akhir"
                    append-icon="mdi-calendar"
                    class="mt-2"
                    hint="Tanggal Akhir"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="eItem.periode.akhir" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="pickakhir = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.pickakhir.save(eItem.periode.akhir)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-n10">
            <v-col cols="12" md="2" class="mt-3">
              <span>Alamat Selama Menjalankan Cuti</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-textarea
                v-model="eItem.alamat"
                class="mt-2"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2" class="mt-3">
              <span>Upload Dokumen</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input
                class="mt-2"
                outlined
                dense
                flat
                prepend-icon
                accept=".pdf"
                label="Dokumen"
                placeholder="Pilih File"
                append-icon="mdi-paperclip"
                @change="onUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-divider class="mb-5"></v-divider>

          <div class="text-right">
            <v-btn
              color="primary"
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/rwcuti/viewRwcuti";
import modal_edit from "@/store/asn/rwcuti/modal_edit";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    modalPDF: false,
    lbtn: false,

    picksk: false,
    pickawal: false,
    pickakhir: false,

    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    get_nip: "",
    doc: "",

    refjencut: [],

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalEdit() {
      this.refjencut = await getRef.jencut(this.token);
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_edit.state.ModalEdit;
      },

      set(value) {
        modal_edit.commit("toggleModal", value);
      },
    },

    eItem: {
      get() {
        return modal_edit.state.rwcuti;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    onUpload(value) {
      this.doc = value;
    },

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url = process.env.VUE_APP_ASN + "cuti/nip/" + this.get_nip;

        const data = new FormData();
        data.append("idcuti", this.eItem.id);
        data.append("doc", this.doc);
        data.append("jencuti", this.eItem.jenis_cuti.id);
        data.append("alasan_cuti", this.eItem.alasan);
        data.append("sk_pejabat", this.eItem.sk.pejabat);
        data.append("sk_nomor", this.eItem.sk.nomor);
        data.append("sk_tanggal", this.eItem.sk.tanggal);
        data.append("tgl_awal", this.eItem.periode.awal);
        data.append("tgl_akhir", this.eItem.periode.akhir);
        data.append("alamat_cuti", this.eItem.alamat);

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    closeModal() {
      this.ModalEdit = false;
    },
  },
};
</script>
