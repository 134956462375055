<template>
  <div>
    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Jenis Cuti</span>
      </v-col>

      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.jenis_cuti.nama"
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Alasan</span>
      </v-col>
      <v-col cols="12" md="10">
        <v-textarea
          v-model="vitem.alasan"
          outlined
          dense
          readonly
          filled
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mb-n8">
      <v-col cols="12" md="2" class="mt-3">
        <span>Surat Keputusan (SK)</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.sk.pejabat"
          hint="Pejabat"
          persistent-hint
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.sk.nomor"
          hint="Nomor"
          persistent-hint
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.sk.tanggal"
          append-icon="mdi-calendar"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n8">
      <v-col cols="12" md="2" class="mt-3">
        <span>Periode Cuti</span>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.periode.awal"
          append-icon="mdi-calendar"
          hint="Tanggal Awal"
          persistent-hint
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.periode.akhir"
          append-icon="mdi-calendar"
          hint="Tanggal Akhir"
          persistent-hint
          outlined
          dense
          readonly
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" class="mt-3">
        <span>Alamat Selama Menjalankan Cuti</span>
      </v-col>
      <v-col cols="12" md="10">
        <v-textarea
          v-model="vitem.alamat"
          outlined
          dense
          readonly
          filled
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  props: ["vitem"],

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),
  }),
};
</script>
