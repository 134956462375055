<template>
  <div v-if="session">
    <v-data-table
      :headers="headers"
      :items="data"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>

        <rwcutiModalEdit />
        <rwcutiModalHapus />
      </template>

      <template v-slot:[`item.sk`]="{ item }">
        <div class="py-1">
          Pejabat : {{ item.sk.pejabat }} <br />
          Nomor : {{ item.sk.nomor }} <br />
          Tanggal : {{ item.sk.tanggal }}
        </div>
      </template>

      <template v-slot:[`item.periode`]="{ item }">
        <div class="py-1">
          Awal : {{ item.periode.awal }} <br />
          Akhir : {{ item.periode.akhir }}
        </div>
      </template>

      <template v-slot:[`item.dokumen`]="{ item }">
        <div v-if="item.doc != null">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="download(item.id)" v-bind="attrs" v-on="on">
                <v-icon color="red"> mdi-file-pdf-box </v-icon>
              </v-btn>
            </template>
            <span> Dokumen Cuti </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="viewItem(item)"> mdi-eye </v-icon>
        <v-icon
          small
          class="mx-2"
          @click="editItem(item)"
          v-if="session.authorities.some((item) => item === 'CRUD_CUTI')"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="session.authorities.some((item) => item === 'CRUD_CUTI')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div v-if="sdata">
      <v-divider class="mb-5"></v-divider>

      <v-card outlined>
        <v-toolbar color="blue-grey lighten-5" dense flat>
          <v-toolbar-title> Detail Data </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="sdata = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-2">
          <rwcutiView :vitem="vdata" />
        </div>
      </v-card>
    </div>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwcutiView from "@/components/asn/profil/rwcuti/View";
import rwcutiModalEdit from "@/components/asn/profil/rwcuti/ModalEdit";
import rwcutiModalHapus from "@/components/asn/profil/rwcuti/ModalHapus";

import modal_edit from "@/store/asn/rwcuti/modal_edit";
import modal_hapus from "@/store/asn/rwcuti/modal_hapus";
import refreshView from "@/store/asn/rwcuti/viewRwcuti";

export default {
  components: {
    rwcutiView,
    rwcutiModalEdit,
    rwcutiModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        { text: "Jenis Cuti", value: "jenis_cuti.nama" },
        { text: "Surat Keputusan", align: "start", value: "sk" },
        { text: "Periode", value: "periode" },
        { text: "Alasan", value: "alasan" },
        {
          text: "File",
          value: "dokumen",
          width: "80px",
          align: "center",
          sortable: false,
        },
      ];

      if (this.session.authorities.some((item) => item === "CRUD_CUTI")) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "100px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    loading: false,
    sdata: false,

    session: "",
    get_nip: "",

    modalPDF: false,
    lbtn: false,
    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    data: [],
    vdata: {},
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_ASN + "cuti/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          refreshView.commit("refreshData", false);
          this.data = response.data.mapData.RwCuti;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    viewItem(item) {
      this.vdata = item;
      this.sdata = true;
    },

    editItem(item) {
      modal_edit.commit("toggleModal", true);
      modal_edit.commit("editModal", item);
    },

    deleteItem(item) {
      modal_hapus.commit("toggleModal", true);
      modal_hapus.commit("hapusModal", item);
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen Cuti";

      const url =
        process.env.VUE_APP_API +
        "/idasn/cuti/doc/" +
        this.get_nip +
        "/" +
        value;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
